import React, { useState } from "react"
import Layout from "../components/layout"
import { RaisedFloorDesc } from "../graphql/RaisedFloorSmallQueries"
import { Desc } from "../graphql/SidingSmallQueries"
import { AllRaisedFloors } from "../graphql/AllRaisedFloorQuery"
import { AllSiding } from "../graphql/AllSidingQuery"
import PageHeader from "./PageHeader"
import FloorProductIntroduction from "../components/FloorProductIntroduction"
import GeneralDescription from "../components/GeneralDescription"
import PageTitle from "../components/PageTitle"
import SEO from "../components/seo"

const FloorMainPageTemplate = ({ pageContext }) => {
  const page = pageContext?.page ?? undefined
  const isSiding = page === "siding"

  const queryData = isSiding ? AllSiding() : AllRaisedFloors()
  const [value, setValue] = useState(0)
  const menuLength = queryData.length
  const arrangedQueryData = queryData

  const menu = arrangedQueryData?.map((item, index) => {
    const name = item?.name ?? undefined
    return (
      <div
        key={index}
        onClick={() => setValue(index)}
        className={`cursor-pointer outline-none focus:outline-none font-semibold  flex items-center justify-center text-center relative  px-2 text-sm ${
          index === value
            ? menuLength < 5
              ? " text-gray-900 bg-white flex-shrink max-w-30 md:max-w-none"
              : "text-white bg-gray-600"
            : "bg-gray-50 border-b flex-grow text-gray-600"
        } ${menuLength < 5 ? "h-24" : "h-14"}`}
        onClick={() => setValue(index)}
      >
        <div
          className={`${
            index === value
              ? "hidden"
              : "flex md:hidden absolute inset-0 items-center justify-center text-xl font-semibold"
          }`}
        >
          {index + 1}
        </div>
        <div className={`${index === value ? "flex" : "hidden md:flex"}`}>
          {name}
        </div>
      </div>
    )
  })

  const body = arrangedQueryData.map((item, index) => {
    return (
      <div key={index}>
        <FloorProductIntroduction data={item} isSiding={isSiding} />
      </div>
    )
  })

  const floorImage = RaisedFloorDesc()[0].image ?? undefined
  const sidingImage = Desc()[0].image ?? undefined
  const floorTitle = RaisedFloorDesc()[0].name ?? undefined
  const sidingTitle = Desc()[0].name ?? undefined
  const floorMd = RaisedFloorDesc()[0].md ?? undefined
  const sidingMd = Desc()[0].md ?? undefined

  const headerImage = isSiding ? sidingImage : floorImage
  const headerTitle = isSiding ? sidingTitle : floorTitle
  const md = isSiding ? sidingMd : floorMd
  // console.log(md)
  return (
    <Layout>
      <SEO title={headerTitle} />
      {/* <PageHeader image={headerImage} title={headerTitle} tall /> */}
      <PageTitle title={headerTitle} />
      <div className="mx-auto my-10">
        <div className="relative z-10 max-w-screen-lg my-10 bg-white border sm:mx-4 lg:mx-auto">
          <div
            className={`${
              menuLength < 5
                ? "flex flex-1 divide-x justify-evenly"
                : "grid grid-cols-5 divide-x"
            }`}
          >
            {menu}
          </div>
          {body[value]}
        </div>
        <GeneralDescription desc={md} sectionName={headerTitle} />
      </div>
    </Layout>
  )
}

export default FloorMainPageTemplate
